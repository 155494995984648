import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import Title from '../components/Title';
import Button from '../components/Button';
import FortuneResImage from '../components/FortuneResImage';
import SnsShareButtons from '../components/SnsShareButtons';
import ListItem from '../components/ListItem';
import Seo from '../components/Seo';
import '../pages/selected/style.sass';

export default props => {
  const PostContent = HTMLContent || Content;
  const { data } = props.pageContext;
  const recommends = props.data.allSelectedCsv.edges;
  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.description}
        href={props.location.href}
      />
      <Title>{data.title}</Title>
      <FortuneResImage score={data.score} />
      <PostContent className="content" content={data.body} />
      <Title>こちらの夢もチェック</Title>
      <div className="linkList">
        {recommends.map((item, i) => {
          return (
            <div key={i}>
              <ListItem to={`/selected/${item.node.id}`}>
                <div className="listItem__header">{item.node.title}</div>
                <div className="listItem__body">{item.node.description}</div>
              </ListItem>
              {i % 30 === 1 ? (
                <div className="listItem">
                  <a
                    href="https://px.a8.net/svt/ejp?a8mat=3BSP80+91O4OI+2H0Q+631SY"
                    rel="nofollow"
                  >
                    <div className="listItem__header">[PR]</div>
                    <div className="listItem__body">
                      彼とやり直したい <br />
                      占いで悩み解決☆
                    </div>
                  </a>
                  <img
                    border="0"
                    width="1"
                    height="1"
                    src="https://www18.a8.net/0.gif?a8mat=3BSP80+91O4OI+2H0Q+631SY"
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div className="selected__section">
        <SnsShareButtons
          location={props.location}
          title={`【夢占い】${data.title}。${data.description} 詳しくはマダムたぬきの夢占いで`}
        />
      </div>
      <div className="selected__nav">
        {/* <div className="selected__navItem">
          <Button label="もどる" to={`/fortune/${data.parentId}`} />
        </div> */}
        <div className="selected__navItem">
          <Button label="トップへ戻る" to={`/`} />
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query selectedPageQuery($parentId: String) {
    allSelectedCsv(filter: { parentId: { eq: $parentId } }) {
      edges {
        node {
          id
          title
          description
          body
          score
          parentId
        }
      }
    }
  }
`;
