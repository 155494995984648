import React from 'react';
import Image from '../Image';
import './style.sass';

export default props => {
  const result = score => {
    let res = { text: '', image: '' };
    if (score === 0) {
      res = { ...res, ...{ text: 'その夢...凶', image: 'img_sadness.png' } };
    } else if (score > 0 && score < 30) {
      res = { ...res, ...{ text: 'その夢...小吉', image: 'img_anger.png' } };
    } else if (score > 30 && score < 60) {
      res = { ...res, ...{ text: 'その夢...中吉', image: 'img_glasses.png' } };
    } else if (score > 60 && score < 90) {
      res = { ...res, ...{ text: 'その夢...吉', image: 'img_normal.png' } };
    } else {
      res = { ...res, ...{ text: 'その夢...大吉！', image: 'img_joy.png' } };
    }
    console.log(res);
    return (
      <>
        <figure className="fortuneResImage__figure">
          <Image filename={res.image} />
        </figure>
        <div className="fortuneResImage__message">{res.text}</div>
      </>
    );
  };

  return <div className="fortuneResImage">{result(props.score)}</div>;
};
